jQuery(document).ready(function($){

	// $.fn.firstWord = function() {
	//   var text = this.text().trim().split(" ");
	//   var first = text.shift();
	//   this.html((text.length > 0 ? "<span class='firstWord'>"+ first + "</span> " : first) + text.join(" "));
	// };

	// $.fn.lastWord = function() {
	//   var text = this.text().trim().split(" ");
	//   var last = text.pop();
	//   this.html(text.join(" ") + (text.length > 0 ? " <span class='hide-last-word-sm'>" + last + "</span>" : last));
	// };

	// // $("class/ID").firstWord();
	// $("header .navbar-button a ins").lastWord();
});